import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import parse from 'html-react-parser';

const PricingPage = () => {
  const data  = useStaticQuery(graphql`
    query PricingPage {
      allWpPage(filter: {title: {eq: "Pricing"}}) {
        nodes {
          title
          pricingPage {
            pricingPageDescription
            basicPricingGroup {
              webDesignResearch
              landingPage
              extraPages
              seo
              bloggingFeatures
              startingPrice
            }
            gatsbyjsPricingGroup {
              webDesignResearch
              landingPage
              extraPages
              seo
              bloggingFeatures
              startingPrice
            }
            wordpressAndGatsbyjsPricingGroup {
              webDesignResearch
              landingPage
              extraPages
              seo
              bloggingFeatures
              startingPrice
            }
            wordpressPricingGroup {
              webDesignResearch
              landingPage
              extraPages
              seo
              bloggingFeatures
              startingPrice
            }
          }
        }
      }
    }
  `);
  

  let pricingPageData = data.allWpPage.nodes[0];
  let pricingDescription = pricingPageData.pricingPage.pricingPageDescription;
  let pageTitle = data.allWpPage.nodes[0].title;

  function checkData(data) {
    if (typeof(data) === 'number' && data > 5) {
      return '$' + data;
    } else if (typeof(data) === 'number') {
      return data;
    }

    if (data === true) {
      return '✔️';
    }
    return '❌'
  }

  let basicData = Object.values(pricingPageData.pricingPage.basicPricingGroup)
    .map((data, index) =>
      <th key={index} scope="col"> {checkData(data)} </th>
    );
  let gatsbyJsData = Object.values(pricingPageData.pricingPage.gatsbyjsPricingGroup)
    .map((data, index) => 
      <th key={index} scope="col"> {checkData(data)} </th>
    );
  let wordpressData = Object.values(pricingPageData.pricingPage.wordpressPricingGroup)
    .map((data, index) => 
      <th key={index} scope="col"> {checkData(data)} </th>
    );
  let wordpressAndGatsbyData = Object.values(pricingPageData.pricingPage.wordpressAndGatsbyjsPricingGroup)
    .map((data, index) => 
      <th key={index} scope="col"> {checkData(data)} </th>
    );
 

  return (
    <Layout>
      <Seo title="Pricing"/>
      <section className="container pricing-page page-default">
        <div className="text-center text-lg-start">
          <h1 className="display-2 pt-5">{  pageTitle }</h1>
        </div>
        <div className="pricing-page-description fs-5">
          {  parse(pricingDescription) }
        </div>
        <div className="table-responsive fs-5 pt-5">
          <table className="table">
            <thead>
              <tr>
                <th scope="col"> </th>
                <th className="text-center" scope="col">Web Design Research</th>
                <th className="text-center" scope="col">Landing Page</th>
                <th className="text-center" scope="col">Extra Pages</th>
                <th className="text-center" scope="col">SEO</th>
                <th className="text-center" scope="col">Blogging Features</th>
                <th className="text-center" scope="col">Starting Price (USD)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th className="text-start" scope="row">Basic (Wix, GoDaddy, etc)</th>
                { basicData }
              </tr>
              <tr>
                <th className="text-start" scope="row">Gatsby.js</th>
                { gatsbyJsData }
              </tr>
              <tr>
                <th className="text-start" scope="row">WordPress</th>
                { wordpressData }
              </tr>
              <tr>
                <th className="text-start" scope="row">WordPress + Gatsby.js</th>
                { wordpressAndGatsbyData }
              </tr>
            </tbody>
          </table>
        </div>

        <p className="fs-5 pt-5">If you have not checked out what our services are, please 
          click <Link to="/services">here</Link>.</p>
      </section>
    </Layout>
  );
}

export default PricingPage;